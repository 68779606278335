import React, { useState, useEffect, useRef } from "react"
import styled, { keyframes } from "styled-components"
import Layout from "../components/Layout/Layout"
import { motion } from "framer-motion"
import { useWpHomepageSelectionNew } from "../hooks/useWpHomepage"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const marqueeHorAnimation = (startX, distance) => keyframes`
0% {
    transform: translateX(100%)
  }
  100% {
    
    transform: translateX(-100%)
  }
`

const Playground = styled(motion.section)`
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;

  // width: max-content;
  width: 10vw;
  height: 60px;
  // position: fixed;
  top: calc(50% - 30px);
  left: 0;
  border: 1px solid gray;

  .moving-part {
    border: 2px solid blue;
    white-space: nowrap;
    width: fit-content;
    display: flex;
    position: absolute;
    left: 100%;
    top: 0;

    animation-play-state: running;
    animation-name: ${marqueeHorAnimation()};
    animation-duration: 5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    &:nth-of-type(2) {
      animation-delay: 2.5s;
    }

    animation-play-state: ${props => (props.$isplaying ? "running" : "paused")};
    animation-name: ${props =>
      props.startX
        ? marqueeHorAnimation(props.startX, props.distance)
        : marqueeHorAnimation(props.startX, props.distance)};
    animation-duration: 20s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    .gatsby-image-wrapper {
      padding-right: 10px;
      // min-width: 100px;
    }

    .miniature-container-mobile {
      border: 1px solid red;
      min-width: 100px;

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }
    }

    &:nth-of-type(2) {
      transform: translateX(100%);
      animation-delay: 10s;
    }
  }
`

const Square = styled.div`
  min-width: 60px;
  width: 60px;
  height: 60px;
  border-right: 1px solid red;
  border-left: 1px solid red;
`
const Hor = () => {
  const { mobile, desktop, desktopFeaturedImages } = useWpHomepageSelectionNew()
  const playgroundRef = useRef(null)
  const miniatureRef = useRef(null)
  const [isTapping, setIsTapping] = useState(true)
  const [startX, setStartX] = useState(0)
  const [distance, setDistance] = useState(0)

  useEffect(() => {
    if (isTapping) {
      console.log("tap [start]")
    } else {
      console.log("tap [end]")
    }
  }, [isTapping])

  useEffect(() => {
    // console.log(miniatureRef.current.getBoundingClientRect().width)
    // console.log(playgroundRef.current.getBoundingClientRect().width)
    setDistance(
      miniatureRef.current.getBoundingClientRect().width +
        playgroundRef.current.getBoundingClientRect().width
    )
    setStartX(playgroundRef.current.getBoundingClientRect().width)
  }, [])
  console.log(distance)
  return (
    <Layout>
      <section className="horizontal-marquee-section-container gv-container">
        <Playground
          ref={playgroundRef}
          startX={startX}
          distance={distance}
          $isplaying={!isTapping}
          onTapStart={() => setIsTapping(true)}
          onTap={() => setIsTapping(false)}
        >
          <div ref={miniatureRef} className="moving-part">
            {mobile.homepage.selectedArtworks.map((selected, index) => {
              // console.log(selected.featuredImage.node.localFile)
              return (
                <div className="miniature-container-mobile" key={`${index}`}>
                  <GatsbyImage
                    image={getImage(selected.featuredImage?.node.localFile)}
                    alt=""
                  />
                </div>
              )
            })}
          </div>
          <div className="moving-part">
            {mobile.homepage.selectedArtworks.map((selected, index) => {
              return (
                <div className="miniature-container-mobile" key={`2_${index}`}>
                  <GatsbyImage
                    image={getImage(selected.featuredImage?.node.localFile)}
                    alt=""
                  />
                </div>
              )
            })}
          </div>
        </Playground>
      </section>
    </Layout>
  )
}

export default Hor
